//carousel slider
.swiper-carousel {
  padding-bottom: 32px;
  max-width: 1200px;
  .swiper-slide {
    width: 520px;
    height: 380px;
    border-radius: 8px;
    background: #18212b;
    max-width: calc(100% - 48px);
  }
  .swiper-carousel-animate-opacity {
    height: 100%;
  }
  .swiper-pagination-bullets {
    bottom: 0;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 8px;
  }
  .slide-content {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    padding: 88px 16px 24px;
    box-sizing: border-box;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75)
    );
    border-radius: 0 0 8px 8px;
    h2 {
      margin: 0;
      font-weight: bold;
      font-size: 24px;
      line-height: 1.1;
      color: #fff;
    }
    p {
      margin: 8px 0 0;
      opacity: 0.65;
      font-size: 14px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: #fff;
    }
  }

  @media (max-width: 640px) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none !important;
    }
  }
}
